import Aos from 'aos';
import 'aos/dist/aos.css';
import '../styles/index.scss';
import { useEffect, useState } from 'react';
import ScrollToTop from '../components/common/ScrollTop';
import { Provider } from 'react-redux';
import store from './api/store';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import Loader from '../components/loader';

const queryClient = new QueryClient();





if (typeof window !== 'undefined') {
  require('bootstrap/dist/js/bootstrap');
}

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    Aos.init({
      duration: 1400,
      once: true,
    });
  }, []);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      // Simulate an async operation like data fetching
      setTimeout(() => {
        setIsLoading(false);
      }, 2500);
    }, []);

    if (isLoading) {
      return <Loader />;
    }
  return (
    <div className='page-wrapper'>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
        </QueryClientProvider>
      </Provider>

      <ToastContainer
        position='bottom-right'
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />

      <ScrollToTop />
    </div>
  );
}
export default MyApp;
