import React from 'react';

const Loader = () => {
    return (
      <div className='loader-container'>
        <span className='loader'></span>
      </div>

      // <div
      //   style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      // >
      //   Loading...
      // </div>
    );
};

export default Loader;